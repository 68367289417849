<template>
  <div>
    <div class="custom_page_wrap">
      <iframe :src="pageUrl" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import CurList from "components/course/CurList"
import CurDetail from "components/course/CurDetail"
import { pageList } from "api/page/index"
export default {
  name: "DynamicPage",
  components: {
    CurList,
    CurDetail
  },
  data () {
    return {
      page_url: '',
      type: '',
      pageUrl: '',
      linkUrl: '',
    }
  },
  mounted() {
    this.page_url = this.$route.params.page_url.join('/');
    this.showPageDetail();
  },
  methods: {
    showPageDetail() {
      var url = this.page_url
      let filters = {'page_url': url,
                      'state': 1 }
      pageList(filters).then((res => {
        this.pageUrl = res.data[0].page_file_dir
      })).catch(() => {
        window.location.href = "/404";
      });
    },
  },
  watch: {
    $route(to,from){
      this.name = to.params.nav_name
    }
  }
}
</script>

<style lang="scss">
.container .el-main {
  min-height: auto !important;
}
.custom_page_wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
